import i18n from "i18next"
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-xhr-backend'
import fr from '../locales/fr.json'
import en from '../locales/en.json'

const bi18nKey = '1570305333224_UN1LyBmRMxZPHOISICJwPMXhxVZVdb27Zdm9'
const lng = process.env.__LANG || 'fr'
const options = {
  lng,
  fallbackLng: 'fr',
  debug: false,
  saveMissing: true,
  react: {
    wait: true,
  }
}


if (process.env.NODE_ENV === 'production') {
  options.resources = {
    fr: {
      translation: fr
    },
    en: {
      translation: en
    }
  }
} else {
  options.backend = {
    loadPath: `https://api.bi18n.pitbi.be/locales/${lng}?key=${bi18nKey}`,
    addPath: `https://api.bi18n.pitbi.be/keys?key=${bi18nKey}`,
    parsePayload: (namespace, key, fallbackValue) => {
      return {
        path: key
      }
    }
  }
}

i18n
  .use(process.env.NODE_ENV === 'production' ? {} : backend)
  .use(initReactI18next)
  .init(options)
  
export default i18n